import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"
import Divider from "../common/Divider"

const DATA = {
  title: "Mind",
  descriptions: [
    "PNOĒ Precision tracks your heart rate variability and heart rate during low physical activity levels to gauge your psychosomatic stress levels. It also uses your breath profile in the background to analyze your heart rate variability in greater detail and detect moments of reduced cognitive capacity.",
  ],
}

export default function Mind() {
  return (
    <Container
      bleed="both"
      css={`
        padding-bottom: 10rem;
      `}
    >
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
      <Container.Image align="center">
        <StaticImage
          src="../../assets/images/pnoe-coach/recovery.png"
          alt="phone"
          width={587}
          placeholder="blurred"
        />
        <Divider.Light />
      </Container.Image>
    </Container>
  )
}

Mind.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={0}>
      {DATA.title}
    </Container.Title>
    <Container.Image align="center">
      <StaticImage
        src="../../assets/images/pnoe-coach/mind.png"
        alt="phone"
        width={587}
        placeholder="blurred"
      />
      <Divider.Light />
    </Container.Image>
    <Container.Description width="43rem" mt={40}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
