import React from "react"
import { DEVICES } from "../config"
import useWindowSize from "../hooks/useWindowSize"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Divider from "../components/common/Divider"
import TrackedTogether from "../components/pnoe-coach/TrackedTogether"
import Wearable from "../components/pnoe-coach/Wearable"
import Training from "../components/pnoe-coach/Training"
import Nutrition from "../components/pnoe-coach/Nutrition"
import Recovery from "../components/pnoe-coach/Recovery"
import Mind from "../components/pnoe-coach/Mind"
import Hero from "../components/pnoe-coach/Hero"

export default function PnoeCoachPage() {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="PNOĒ Coach" />
      <Divider.Light />
      {width < DEVICES.desktop ? (
        <>
          <Hero.Mobile />
          <Divider.Light />
          <TrackedTogether.Mobile />
          <Divider.Light />
          <Wearable.Mobile />
          <Divider.Light />
          <Training.Mobile />
          <Nutrition.Mobile />
          <Recovery.Mobile />
          <Mind.Mobile />
        </>
      ) : (
        <>
          <Hero />
          <Divider.Light />
          <TrackedTogether />
          <Divider.Light />
          <Wearable />
          <Divider.Light />
          <Training />
          <Nutrition />
          <Recovery />
          <Mind />
        </>
      )}
    </Layout>
  )
}
