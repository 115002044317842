import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../why-get-tested/Container"
import Button from "../common/Button"
import { Link } from "gatsby"
import HeroLottie from "../common/HeroLottie"

const DATA = {
  title: "A sport scientist and a lab in your pocket.",
  descriptions: [
    "The PNOĒ Precision app syncs your metabolic profile with your smartwatch making it able to track your activity, nutrition, physical recovery, and mental readiness like never before.",
  ],
  button: {
    name: "Book your test now",
    to: "/payment",
  },
}

export default function Hero() {
  return (
    <Container
      bleed="both"
      css={`
        padding-top: 5rem;
      `}
    >
      <div>
        <Container.Title width="41rem">{DATA.title}</Container.Title>
        <Container.Description width="39rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Container.Image align="right">
        <StaticImage
          src="../../assets/images/pnoe-coach/hero.png"
          width={320}
          placeholder="blurred"
          quality={100}
        />
      </Container.Image>
      <div
        css={`
          max-width: 20rem;
        `}
      >
        <HeroLottie />
      </div>
    </Container>
  )
}

Hero.Mobile = () => (
  <Container
    bleed="both"
    css={`
      .gatsby-image-wrapper {
        margin: 0;
      }
    `}
  >
    <Container.Title width="41rem">{DATA.title}</Container.Title>
    <Container.Description width="39rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <StaticImage
      src="../../assets/images/pnoe-coach/hero.png"
      alt="bicycle exercise"
      width={846}
      placeholder="blurred"
    />
    <Button as={Link} to={DATA.button.to} mt={24}>
      {DATA.button.name}
    </Button>
  </Container>
)
