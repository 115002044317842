import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Supercharge your clients' wearable and put a lab on their wrist.",
  descriptions: [
    "The PNOĒ Precision app calibrates your wearable with your breath data and makes it able to monitor your metabolism and performance like never before. Your calibrated wearable data then merge with our predictive analytics to monitor the evolution of performance metrics such as your VO2max, fat-burning efficiency, metabolic efficiency, lung, and heart fitness.",
  ],
  button: {
    name: "Dive into the science",
    to: "/the-science",
  },
}

export default function Wearable() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Container.Image align="center">
        <StaticImage
          src="../../assets/images/pnoe-coach/wearable.png"
          alt="phone"
          width={337}
          placeholder="blurred"
        />
      </Container.Image>
    </Container>
  )
}

Wearable.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={24}>
      {DATA.title}
    </Container.Title>
    <StaticImage
      src="../../assets/images/pnoe-coach/wearable.png"
      alt="phone"
      width={337}
      placeholder="blurred"
    />
    <Container.Description width="43rem" mt={24}>
      {DATA.descriptions[0]}
    </Container.Description>
    <Button as={Link} to={DATA.button.to}>
      {DATA.button.name}
    </Button>
  </Container>
)
