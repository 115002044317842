import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"
import Divider from "../common/Divider"

const DATA = {
  title: "Recovery",
  descriptions: [
    "PNOĒ Precision tracks your sleep, sleep quality, and stress from training to measure your body's recovery. Depending on your recovery level, it will adjust the recommended workout intensity to help you get the most out of your training while avoiding injuries. It also syncs with the recovery profile measured by the PNOĒ test and tracks how effectively your body recovers during exercise, helping you manage your effort levels.",
  ],
}

export default function Recovery() {
  return (
    <Container bleed="both">
      <Container.Image align="center">
        <StaticImage
          src="../../assets/images/pnoe-coach/recovery.png"
          alt="phone"
          width={587}
          placeholder="blurred"
        />
        <Divider.Light />
      </Container.Image>
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
    </Container>
  )
}

Recovery.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={0}>
      {DATA.title}
    </Container.Title>
    <Container.Image align="center">
      <StaticImage
        src="../../assets/images/pnoe-coach/recovery.png"
        alt="phone"
        width={587}
        placeholder="blurred"
      />
      <Divider.Light />
    </Container.Image>
    <Container.Description width="43rem" mt={40}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
