import React, { useState } from "react"
import hero1 from "../../assets/lottie/hero-1.json"
import hero2 from "../../assets/lottie/hero-2.json"
import CustomSwiper from "./CustomSwiper"
import { SwiperSlide } from "swiper/react"
import { DEVICES } from "../../config"
import Lottie from "react-lottie-player"

function HeroSlide({ lottie, play }) {
  return play ? (
    <Lottie
      play={play}
      animationData={lottie}
      css={`
        height: 280px;
        width: 280px;
        margin-left: auto;

        @media (max-width: ${DEVICES.tablet}px) {
          height: 200px;
          width: 200px;
          margin-left: unset;
          position: absolute;
          right: 0;
          top: 0;
        }
      `}
    />
  ) : (
    <div
      css={`
        @media (max-width: ${DEVICES.tablet}px) {
          height: 230px;
          width: 230px;
          margin-left: unset;
        }
      `}
    ></div>
  )
}

export default function HeroLottie() {
  const [index, setIndex] = useState(0)

  return (
    <CustomSwiper
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      effect="fade"
      id="hero-slider"
      loop
      slidesPerView={1}
      css={`
        .hero-slider-next,
        .hero-slider-prev {
          display: none;
        }
      `}
      onSlideChange={swiper => setIndex(swiper.realIndex)}
    >
      <SwiperSlide>
        <HeroSlide lottie={hero1} play={index === 0} />
      </SwiperSlide>
      <SwiperSlide>
        <HeroSlide lottie={hero2} play={index === 1} />
      </SwiperSlide>
    </CustomSwiper>
  )
}
