import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"
import Divider from "../common/Divider"

const DATA = {
  title: "Training",
  descriptions: [
    "PNOĒ Precision tracks your nutrition, physical recovery, and sleep to adjust your workout routine and keep it in line with your lifestyle and performance goal. It also syncs with your training zones and metabolic profile to help you stay within the optimal exercise intensities that will get you the most out of your workouts.",
  ],
}

export default function Training() {
  return (
    <Container bleed="both">
      <Container.Image align="center">
        <StaticImage
          src="../../assets/images/pnoe-coach/training.png"
          alt="phone"
          width={587}
          placeholder="blurred"
        />
        <Divider.Light />
      </Container.Image>
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
    </Container>
  )
}

Training.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={0}>
      {DATA.title}
    </Container.Title>
    <Container.Image align="center">
      <StaticImage
        src="../../assets/images/pnoe-coach/training.png"
        alt="phone"
        width={587}
        placeholder="blurred"
      />
      <Divider.Light />
    </Container.Image>
    <Container.Description width="43rem" mt={40}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
