import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"

const DATA = {
  title: "Activity, Nutrition, Recovery, and Mind, all tracked together.",
  descriptions: [
    "We believe that finding the problem and pointing to the solution is not enough. Sustainable results require guidance every step of the way. That's why we built PNOĒ Precision, the first AI-powered app that guides you 24/7 on all four pillars of performance: Activity, Nutrition, Physical Recovery, and Mental Readiness. All calibrated to your metabolism.",
  ],
}

export default function TrackedTogether() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
      <Container.Image align="center">
        <StaticImage
          src="../../assets/images/pnoe-coach/tracked-together.png"
          alt="phone"
          width={465}
          placeholder="blurred"
        />
      </Container.Image>
    </Container>
  )
}

TrackedTogether.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem">{DATA.title}</Container.Title>
    <StaticImage
      src="../../assets/images/pnoe-coach/tracked-together.png"
      alt="phone"
      width={465}
      placeholder="blurred"
    />
    <Container.Description width="43rem" mt={24} mb={0}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
