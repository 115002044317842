import React from "react"
import styled from "styled-components"
import { MEDIA_QUERIES } from "../../config"

export const Text = styled.div`
  margin: ${props =>
    `${props.mt || 0}px ${props.mr || 0}px ${props.mb || 0}px ${
      props.ml || 0
    }px`};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  font-size: ${props => props.theme.fontSize[props.size]}px;
  font-weight: ${props => (props.weight ? props.weight : "normal")};
  line-height: ${props => props.theme.lineHeight[props.size]};
  text-align: ${props => (props.align ? props.align : "inherit")};

  ${MEDIA_QUERIES.belowDesktop} {
    font-size: ${props => props.theme.mobileFontSize[props.size]}px;
    line-height: ${props => props.theme.mobileLineHeight[props.size]};
  }
`

export const H1 = props => <Text as="h1" size="lg" weight="bold" {...props} />
export const P = props => <Text as="p" size="base" opacity={0.7} {...props} />
